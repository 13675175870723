<template>
  <div class="app">
    <transition name="fade">
      <div class="wrapper" v-if="!isLoad">
        <transition-group
          :name="isPageBack ? 'page-back' : 'page-next'"
          tag="div"
          class="content"
        >
          <component :is="page.component" :key="page.id" class="inner" />
        </transition-group>
        <Navbar />
      </div>
    </transition>

    <transition name="fade">
      <div v-if="isLoad" class="loader">
        <div class="title">
          <svg width="214" height="91" viewBox="0 0 214 91" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M33 49H44.3099C46.3445 49 48.1996 49.4356 49.8751 50.3069C51.5506 51.1481 52.8671 52.3498 53.8246 53.912C54.782 55.4442 55.2608 57.2017 55.2608 59.1845C55.2608 61.1674 54.782 62.9399 53.8246 64.5021C52.8671 66.0644 51.5506 67.2811 49.8751 68.1524C48.1996 68.9936 46.3445 69.4142 44.3099 69.4142H39.0589V81.2661H33V49ZM44.4894 63.6459C45.9854 63.6459 47.1523 63.2103 47.9901 62.3391C48.8578 61.4678 49.2916 60.4163 49.2916 59.1845C49.2916 57.9528 48.8578 56.9163 47.9901 56.0751C47.1523 55.2039 45.9854 54.7682 44.4894 54.7682H39.0589V63.6459H44.4894Z" fill="#FFD141"/>
            <path d="M66.7211 80.1845L57.0718 58.2833H63.759L69.6832 72.5687H70.0422L75.7421 58.2833H82.2946L68.1573 91H61.8291L66.7211 80.1845Z" fill="#FFD141"/>
            <path d="M95.4951 81.6266C94.3582 81.6266 93.311 81.4614 92.3535 81.1309C91.396 80.7704 90.6181 80.2897 90.0197 79.6888C88.6135 78.3369 87.9103 76.4142 87.9103 73.9206V63.3305H83.916V58.2833H87.9103V51.794H93.7897V58.2833H99.3997V63.3305H93.7897V72.8391C93.7897 74.0107 94.044 74.8519 94.5526 75.3627C94.9715 75.8433 95.6896 76.0837 96.7069 76.0837C97.2754 76.0837 97.7392 76.0086 98.0982 75.8584C98.4872 75.7082 98.9809 75.4528 99.5793 75.0923V80.8605C98.2927 81.3712 96.9313 81.6266 95.4951 81.6266Z" fill="#FFD141"/>
            <path d="M104.509 49H110.389V57.1567L110.03 61.1674H110.389C111.047 60.0858 112.005 59.2146 113.261 58.5536C114.518 57.8927 115.879 57.5622 117.345 57.5622C120.128 57.5622 122.252 58.4034 123.718 60.0858C125.214 61.7382 125.962 64.0365 125.962 66.9807V81.2661H120.083V67.7017C120.083 66.2296 119.679 65.088 118.871 64.2768C118.093 63.4657 117.046 63.0601 115.73 63.0601C114.174 63.0601 112.887 63.691 111.87 64.9528C110.883 66.2146 110.389 67.7618 110.389 69.5944V81.2661H104.509V49Z" fill="#FFD141"/>
            <path d="M142.459 81.9871C140.095 81.9871 137.986 81.4614 136.131 80.4099C134.276 79.3283 132.824 77.8712 131.777 76.0386C130.76 74.176 130.251 72.088 130.251 69.7747C130.251 67.4914 130.76 65.4185 131.777 63.5558C132.824 61.6931 134.276 60.2361 136.131 59.1845C137.986 58.103 140.095 57.5622 142.459 57.5622C144.793 57.5622 146.887 58.103 148.742 59.1845C150.597 60.2361 152.033 61.6931 153.051 63.5558C154.098 65.4185 154.621 67.4914 154.621 69.7747C154.621 72.088 154.098 74.176 153.051 76.0386C152.033 77.8712 150.597 79.3283 148.742 80.4099C146.887 81.4614 144.793 81.9871 142.459 81.9871ZM142.459 76.5343C143.596 76.5343 144.643 76.2639 145.6 75.7232C146.558 75.1824 147.321 74.4013 147.889 73.3798C148.458 72.3584 148.742 71.1567 148.742 69.7747C148.742 68.4227 148.458 67.2361 147.889 66.2146C147.321 65.1931 146.558 64.412 145.6 63.8712C144.643 63.3305 143.596 63.0601 142.459 63.0601C141.322 63.0601 140.275 63.3305 139.317 63.8712C138.36 64.412 137.582 65.1931 136.983 66.2146C136.415 67.2361 136.131 68.4227 136.131 69.7747C136.131 71.1266 136.415 72.3283 136.983 73.3798C137.582 74.4013 138.36 75.1824 139.317 75.7232C140.275 76.2639 141.322 76.5343 142.459 76.5343Z" fill="#FFD141"/>
            <path d="M158.682 58.2833H164.202V61.1674H164.561C165.249 60.0558 166.222 59.1846 167.478 58.5536C168.735 57.8927 170.096 57.5622 171.562 57.5622C174.315 57.5622 176.41 58.4034 177.846 60.0858C179.282 61.7682 180 64.0665 180 66.9807V81.2661H174.121V67.7017C174.121 66.2296 173.747 65.088 172.999 64.2768C172.251 63.4657 171.203 63.0601 169.857 63.0601C168.271 63.0601 166.985 63.676 165.997 64.9077C165.04 66.1395 164.561 67.6867 164.561 69.5494V81.2661H158.682V58.2833Z" fill="#FFD141"/>
            <path d="M18.83 38C15.3486 38 12.1684 37.1702 9.28947 35.5107C6.41057 33.8173 4.13423 31.5312 2.46046 28.6524C0.820152 25.7397 0 22.5223 0 19C0 15.4777 0.820152 12.2772 2.46046 9.3984C4.13423 6.48574 6.41057 4.19964 9.28947 2.54011C12.1684 0.846703 15.3486 0 18.83 0C24.2531 0 28.7388 1.98128 32.2872 5.94385L27.5169 10.6176C25.2071 7.87433 22.3282 6.50267 18.8802 6.50267C16.6374 6.50267 14.5954 7.02763 12.7542 8.07754C10.913 9.09358 9.45685 10.5499 8.38563 12.4465C7.34789 14.3431 6.82902 16.5276 6.82902 19C6.82902 21.4724 7.34789 23.6569 8.38563 25.5535C9.45685 27.4501 10.913 28.9234 12.7542 29.9733C14.5954 30.9893 16.6374 31.4973 18.8802 31.4973C22.663 31.4973 25.8264 29.9055 28.3706 26.7219L33.191 31.3449C31.4168 33.5125 29.3079 35.172 26.8642 36.3235C24.4539 37.4412 21.7759 38 18.83 38Z" fill="white"/>
            <path d="M49.8352 38C47.1906 38 44.8306 37.4073 42.7551 36.2219C40.6796 35.0027 39.0561 33.3601 37.8844 31.2941C36.7463 29.1943 36.1772 26.8405 36.1772 24.2326C36.1772 21.6586 36.7463 19.3217 37.8844 17.2219C39.0561 15.1221 40.6796 13.4795 42.7551 12.2941C44.8306 11.0749 47.1906 10.4652 49.8352 10.4652C52.4463 10.4652 54.7896 11.0749 56.8651 12.2941C58.9406 13.4795 60.5474 15.1221 61.6856 17.2219C62.8572 19.3217 63.443 21.6586 63.443 24.2326C63.443 26.8405 62.8572 29.1943 61.6856 31.2941C60.5474 33.3601 58.9406 35.0027 56.8651 36.2219C54.7896 37.4073 52.4463 38 49.8352 38ZM49.8352 31.8529C51.1073 31.8529 52.2789 31.5481 53.3501 30.9385C54.4214 30.3289 55.275 29.4483 55.911 28.2968C56.5471 27.1453 56.8651 25.7906 56.8651 24.2326C56.8651 22.7086 56.5471 21.3708 55.911 20.2193C55.275 19.0677 54.4214 18.1872 53.3501 17.5775C52.2789 16.9679 51.1073 16.6631 49.8352 16.6631C48.5631 16.6631 47.3915 16.9679 46.3203 17.5775C45.2491 18.1872 44.3787 19.0677 43.7092 20.2193C43.0731 21.3708 42.7551 22.7086 42.7551 24.2326C42.7551 25.7567 43.0731 27.1114 43.7092 28.2968C44.3787 29.4483 45.2491 30.3289 46.3203 30.9385C47.3915 31.5481 48.5631 31.8529 49.8352 31.8529Z" fill="white"/>
            <path d="M77.4702 38C74.3905 38 72.0472 37.0348 70.4403 35.1043C68.8335 33.1738 68.0301 30.4813 68.0301 27.0267V11.2781H74.6081V26.2139C74.6081 27.975 75.0265 29.3636 75.8634 30.3797C76.7003 31.3619 77.805 31.8529 79.1775 31.8529C81.0521 31.8529 82.5418 31.1586 83.6465 29.7701C84.7512 28.3815 85.3035 26.6203 85.3035 24.4866V11.2781H91.8815V37.1872H85.7052V33.9358H85.3035C84.5336 35.1889 83.4456 36.1881 82.0396 36.9332C80.6671 37.6444 79.144 38 77.4702 38Z" fill="white"/>
            <path d="M98.2903 11.2781H104.467V14.7326H104.868C105.504 13.5134 106.492 12.5143 107.831 11.7353C109.17 10.9225 110.643 10.516 112.25 10.516C113.421 10.516 114.493 10.7023 115.463 11.0749V17.7299C114.559 17.3913 113.79 17.1542 113.153 17.0187C112.551 16.8494 111.898 16.7647 111.195 16.7647C109.254 16.7647 107.714 17.4759 106.576 18.8984C105.437 20.3209 104.868 22.1159 104.868 24.2834V37.1872H98.2903V11.2781Z" fill="white"/>
            <path d="M128.48 38C125.367 38 122.839 37.3565 120.898 36.0695C118.99 34.7825 117.651 33.1061 116.881 31.0401L122.756 28.4492C123.291 29.6684 124.044 30.5998 125.015 31.2433C126.019 31.8529 127.174 32.1578 128.48 32.1578C129.685 32.1578 130.689 31.9715 131.493 31.5989C132.296 31.1925 132.698 30.549 132.698 29.6684C132.698 28.8217 132.33 28.1952 131.593 27.7888C130.857 27.3485 129.752 26.959 128.279 26.6203L125.266 25.9599C123.191 25.4519 121.467 24.5374 120.094 23.2166C118.722 21.8619 118.036 20.1854 118.036 18.1872C118.036 16.697 118.471 15.3592 119.341 14.1738C120.245 12.9884 121.45 12.074 122.956 11.4305C124.496 10.787 126.187 10.4652 128.028 10.4652C133.317 10.4652 136.849 12.3449 138.623 16.1043L132.999 18.5936C131.995 16.7647 130.388 15.8503 128.179 15.8503C127.041 15.8503 126.137 16.0704 125.467 16.5107C124.798 16.9171 124.463 17.4421 124.463 18.0856C124.463 19.3048 125.601 20.2193 127.877 20.8289L131.643 21.7433C134.188 22.3868 136.096 23.369 137.368 24.6898C138.673 26.0107 139.326 27.6364 139.326 29.5668C139.326 31.2264 138.841 32.6996 137.87 33.9866C136.933 35.2398 135.627 36.2219 133.953 36.9332C132.313 37.6444 130.488 38 128.48 38Z" fill="white"/>
            <path d="M143.845 11.2781H150.021V14.5294H150.423C151.227 13.2763 152.298 12.2941 153.637 11.5829C155.009 10.8378 156.532 10.4652 158.206 10.4652C160.014 10.4652 161.587 10.9055 162.926 11.7861C164.265 12.6328 165.219 13.7166 165.788 15.0374C166.625 13.6827 167.764 12.582 169.203 11.7353C170.676 10.8886 172.4 10.4652 174.375 10.4652C177.287 10.4652 179.497 11.3797 181.003 13.2086C182.543 15.0036 183.313 17.4421 183.313 20.5241V37.1872H176.785V21.7433C176.785 20.1176 176.4 18.8645 175.63 17.984C174.894 17.1034 173.856 16.6631 172.517 16.6631C170.81 16.6631 169.437 17.3574 168.4 18.746C167.395 20.1007 166.893 21.8957 166.893 24.131V37.1872H160.315V21.7433C160.315 20.1176 159.914 18.8645 159.11 17.984C158.34 17.1034 157.235 16.6631 155.796 16.6631C154.189 16.6631 152.884 17.3574 151.879 18.746C150.909 20.1007 150.423 21.8957 150.423 24.131V37.1872H143.845V11.2781Z" fill="white"/>
            <path d="M201.597 38C199.053 38 196.76 37.4073 194.718 36.2219C192.676 35.0365 191.069 33.4109 189.898 31.3449C188.759 29.2451 188.19 26.8913 188.19 24.2834C188.19 21.8111 188.759 19.525 189.898 17.4251C191.036 15.2914 192.609 13.598 194.618 12.3449C196.626 11.0918 198.869 10.4652 201.346 10.4652C203.991 10.4652 206.267 11.041 208.175 12.1925C210.083 13.344 211.523 14.9189 212.494 16.9171C213.498 18.9153 214 21.1337 214 23.5722C214 24.3512 213.95 25.0963 213.849 25.8075H194.618C194.952 27.8734 195.756 29.4483 197.028 30.5321C198.333 31.582 199.89 32.107 201.698 32.107C203.204 32.107 204.493 31.7852 205.564 31.1417C206.669 30.4644 207.539 29.5668 208.175 28.4492L213.498 31.0909C210.887 35.697 206.92 38 201.597 38ZM207.673 21.0829C207.606 20.2701 207.305 19.4742 206.769 18.6952C206.267 17.8824 205.547 17.2219 204.61 16.7139C203.706 16.172 202.635 15.9011 201.396 15.9011C199.823 15.9011 198.467 16.3752 197.329 17.3235C196.224 18.238 195.421 19.4911 194.919 21.0829H207.673Z" fill="white"/>
          </svg>
        </div>
        <Preloader />
      </div>
    </transition>

    <ModalMoreCourses />
    <Article />
    <Section />
    <ArticleSection />
    <Test />

    <Ads />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import Ads from '@/components/ads/Ads'
import Preloader from "@/components/base/Preloader";
import ModalMoreCourses from "@/components/ModalMoreCourses";
import Navbar from "./components/base/Navbar";
import Home from "./views/Home";
import Articles from "./views/Articles";
import Article from "./components/article/Article";
import ArticleSection from "./components/article/ArticleSection";
import Section from "./components/article/Section";
import Tests from "./views/Tests";
import Test from "@/components/test/questions/Wrapper";

export default {
  name: "App",
  components: {
    Navbar,
    Home,
    Articles,
    Tests,
    Test,
    ModalMoreCourses,
    Preloader,
    Article,
    Section,
    ArticleSection,
    Ads
  },
  data() {
    return {
      isMoreCourses: true,
      isPageBack: false,
      prewPage: "home",
      isLoad: true,
    };
  },
  watch: {
    page(page) {
      this.isPageBack = page.id < this.prewPage;
      this.prewPage = page.id;
    },
  },
  computed: {
    ...mapState(["page"]),
  },
  methods: {
    ...mapActions(["initCourses"]),
  },
  async mounted() {
    await this.initCourses();
    this.isLoad = false;
    // setTimeout(async () => {
    //   await this.initCourses();
    //   this.isLoad = false;
    // }, 2000);
  },
};
</script>

<style lang="scss">
.app {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  & > .wrapper > .content {
    position: absolute;
    width: 100%;
    height: calc(100% - var(--navbar-height));
    display: flex;
    flex: 1;
    overflow: hidden;

    .inner {
      width: 100%;
      height: 100%;
      overflow: auto;
    }
  }

  & > .loader {
    background: var(--main);
    color: var(--white);
    font-size: 45px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
}

.page-next-leave-active,
.page-next-enter-active {
  position: absolute;
  transition: 0.4s;
}
.page-next-enter {
  transform: translate(100%, 0);
}
.page-next-leave-to {
  transform: translate(-100%, 0);
}

.page-back-leave-active,
.page-back-enter-active {
  position: absolute;
  transition: 0.4s;
}
.page-back-enter {
  transform: translate(-100%, 0);
}
.page-back-leave-to {
  transform: translate(100%, 0);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.6s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
